<template>
    <div> <RouterView /> </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {
                msg: "Welcome to Your Vue.js App",
            };
        },
    };
</script>
