import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

Sentry.init({
  dsn: "https://ae667f4d8fb060a01f793571d24e0103@o4506915288580096.ingest.us.sentry.io/4506915291791360",
  integrations: [
    Sentry.feedbackIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/rdns\.fr\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.mount("#app");